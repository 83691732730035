export function renderParagraphHtml(paragraphRawText: string) {
  if (paragraphRawText === "") {
    return <br />;
  }
  if (paragraphRawText === "***") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: 50,
          marginBottom: 50,
        }}
      >
        <hr style={{ width: 100 }} />
      </div>
    );
  }
  const italicSplit = paragraphRawText.split("<i>");
  const elements = [];
  for (let i = 0; i < italicSplit.length; i++) {
    if (i % 2 === 0) {
      elements.push(italicSplit[i]);
    } else {
      elements.push(
        <span style={{ fontStyle: "italic" }}>{italicSplit[i]}</span>
      );
    }
  }
  return elements;
}

export function stripTags(paragraphRawText: string) {
  return paragraphRawText.replaceAll("<i>", "");
}
