import { Button, Input } from "@nextui-org/react";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons-react";
import { useMediaQuery } from "react-responsive";
import { renderParagraphHtml, stripTags } from "./renderingUtils";

function Article() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1100px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width: 950px) and (max-width: 1100px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 950px)",
  });
  const isSmallMobile = useMediaQuery({
    query: "(max-width: 500px)",
  });
  const { articleID } = useParams();
  const navigate = useNavigate();
  const [articleTitle, setArticleTitle] = useState<string>("");
  const [articleDate, setArticleDate] = useState<Date>(new Date());
  const [articleType, setArticleType] = useState<string>("");
  const [articleParagraphs, setArticleParagraphs] = useState<string[]>([]);
  const [email, setEmail] = useState<string>("");
  const [buttonText, setButtonText] = useState<string>("Subscribe");
  const [subscribeLoading, setSubscribeLoading] = useState<boolean>(false);
  const [subscriptionError, setSubscriptionError] = useState<boolean>(false);
  useEffect(() => {
    if (articleID && articleParagraphs.length === 0) {
      fetch(`./${process.env.PUBLIC_URL}article-index.txt`).then((res) =>
        res.text().then((text) => {
          const articleIndex = text.split("\n");
          const matchingMetadata = articleIndex.find((a) =>
            a.startsWith(articleID)
          );
          if (matchingMetadata) {
            const [_, title, dateString, articleType] =
              matchingMetadata.split("|");
            setArticleTitle(title);
            setArticleDate(new Date(dateString));
            setArticleType(articleType);
            document.title = stripTags(title);
          }
        })
      );
      fetch(`./${process.env.PUBLIC_URL}articles/${articleID}.txt`)
        .then((res) => res.text())
        .then((text) => {
          const paragraphs = text.split("\n");
          if (paragraphs[0] === "<!DOCTYPE html>") {
            navigate("/");
          }
          setArticleParagraphs(paragraphs);
        });
      const readArticlesLocalStorage = localStorage.getItem("readArticles");
      const articleList = readArticlesLocalStorage
        ? readArticlesLocalStorage.split(",")
        : [];
      if (!articleList.includes(articleID)) {
        articleList.push(articleID);
        localStorage.setItem("readArticles", articleList.join(","));
      }
    }
  }, [articleID, articleParagraphs.length, navigate]);
  return (
    <div
      className="w-full flex flex-row justify-center"
      style={{
        paddingTop: isSmallMobile ? 25 : 50,
        paddingLeft: isSmallMobile ? 25 : 50,
        paddingRight: isSmallMobile ? 25 : 50,
      }}
    >
      <p style={{ fontFamily: "Spectral", fontSize: 0 }}>
        x<span style={{ fontStyle: "italic" }}>x</span>
      </p>
      {(isDesktop || isTablet) && (
        <div className="flex-1 font-sans flex flex-col">
          <div
            className="fixed flex flex-col justify-between h-full"
            style={{
              height: "calc(100vh - 50px)",
              width: `calc((100vw - 850px)${isDesktop ? "/2" : ""})`,
              paddingBottom: 50,
            }}
          >
            <div>
              <div style={{ marginBottom: 10 }}>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    letterSpacing: 1,
                    textWrap: "wrap",
                  }}
                >
                  {renderParagraphHtml(articleTitle)}
                </p>
              </div>
              <div style={{ marginBottom: 10 }}>
                <p className="flex font-sans">
                  {articleDate.toLocaleDateString()}
                </p>
              </div>
              <p
                className="flex font-sans"
                style={{
                  color: articleType === "Short story" ? "#b39015" : "black",
                }}
              >
                {articleType}
              </p>
            </div>
            <div>
              {isDesktop && (
                <Link to="/">
                  <Button isIconOnly={true} size="sm" variant="light">
                    <IconArrowLeft />
                  </Button>
                </Link>
              )}
              {isTablet && (
                <Link to="/">
                  <div style={{ marginBottom: 10 }}>
                    <p className="font-sans">a blog by peter lu</p>
                  </div>
                  <p
                    style={{
                      fontFamily: "Nothing You Could Do",
                    }}
                  >
                    decoding the narwhal
                  </p>
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="w-full" style={{ maxWidth: 700, marginBottom: 50 }}>
        {isMobile && (
          <div style={{ marginBottom: 30 }}>
            <div
              className="flex"
              style={{
                marginLeft: isSmallMobile ? 0 : -48,
                flexDirection: isSmallMobile ? "column" : "row",
                alignItems: isSmallMobile ? "flex-start" : "center",
              }}
            >
              <Link to="/">
                <Button
                  size="sm"
                  variant="light"
                  style={{ minWidth: 0, marginLeft: isSmallMobile ? -12 : 0 }}
                >
                  <IconArrowLeft />
                </Button>
              </Link>
              <p
                className="font-sans"
                style={{
                  fontWeight: "bold",
                  marginBottom: isSmallMobile ? 4 : 0,
                }}
              >
                {renderParagraphHtml(articleTitle)}
              </p>
            </div>

            <p className="flex font-sans">
              {articleDate.toLocaleDateString()}
              <span
                style={{
                  fontWeight: "bold",
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                ·
              </span>
              <span
                style={{
                  color: articleType === "Short story" ? "#b39015" : "black",
                }}
              >
                {articleType}
              </span>
            </p>
          </div>
        )}
        {articleParagraphs.map((a, index) => (
          <p
            key={`paragraph-${index}`}
            style={{ fontFamily: "Spectral", fontSize: 16, marginBottom: 20 }}
          >
            {renderParagraphHtml(a)}
          </p>
        ))}
        {articleParagraphs.length > 0 && (
          <div
            className="flex flex-col items-center"
            style={{ marginTop: 40, marginBottom: 40 }}
          >
            <p className="font-sans" style={{ fontSize: 14 }}>
              Get emails for new blog articles:
            </p>
            <div
              className="flex flex-row items-start"
              style={{ marginTop: 10 }}
            >
              <div style={{ marginRight: 10 }}>
                <Input
                  className="flex-1 font-sans"
                  size="sm"
                  title="email"
                  placeholder="email"
                  onValueChange={(value) => {
                    setSubscriptionError(false);
                    setButtonText("Subscribe");
                    setEmail(value);
                  }}
                  value={email}
                  isInvalid={subscriptionError}
                  errorMessage={
                    subscriptionError
                      ? "Please enter a valid email."
                      : undefined
                  }
                />
              </div>
              <Button
                className="font-sans"
                size="sm"
                color="primary"
                isLoading={subscribeLoading}
                onClick={async () => {
                  const trimmedEmail = email.trim();
                  if (!trimmedEmail) {
                    return;
                  }
                  setSubscribeLoading(true);
                  const response = await fetch(
                    "https://api.convertkit.com/v3/forms/6512867/subscribe",
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        charset: "utf-8",
                      },
                      body: JSON.stringify({
                        email: email,
                        api_key: process.env.REACT_APP_CONVERTKIT_KEY,
                      }),
                    }
                  );
                  setSubscribeLoading(false);
                  const status = response.status;
                  if (status === 200) {
                    setButtonText("Confirmation email sent!");
                  } else {
                    setSubscriptionError(true);
                  }
                }}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        )}
      </div>
      {isDesktop && (
        <div className="flex-1 flex flex-row justify-end">
          <div
            className="flex flex-col justify-end fixed items-end"
            style={{
              height: "calc(100vh - 50px)",
              width: "calc((100vw - 800px)/2)",
              paddingBottom: 50,
              paddingLeft: 30,
            }}
          >
            <Link to="/">
              <div style={{ marginBottom: 10 }}>
                <p className="font-sans" style={{ textAlign: "right" }}>
                  a blog by peter lu
                </p>
              </div>
              <p
                style={{
                  fontFamily: "Nothing You Could Do",
                  textAlign: "right",
                }}
              >
                decoding the narwhal
              </p>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Article;
