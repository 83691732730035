import React, { useEffect, useMemo, useState } from "react";
import "./App.css";
import { Link } from "react-router-dom";
import { clamp } from "framer-motion";
import useWindowDimensions from "./useWindowDimensions";
import { useMediaQuery } from "react-responsive";
import { renderParagraphHtml } from "./renderingUtils";
import NarwhalPng from "./narwhal.png";

type ArticleMetadata = {
  title: string;
  date: Date;
  type: string;
  articleID: string;
};

const MAX_RIGHT_PIXEL_MARGIN = 300;

function Home() {
  const [rightPixelMargin, setRightPixelMargin] = React.useState<number>(
    MAX_RIGHT_PIXEL_MARGIN
  );
  const [readyToVisibleTimeline, setReadyToVisibleTimeline] = useState(false);
  const [articles, setArticles] = React.useState<ArticleMetadata[]>([]);
  const readyToDisplayTimeline = useMemo(() => {
    return articles.length > 0;
  }, [articles.length]);
  const [fetchedIndex, setFetchedIndex] = React.useState<boolean>(false);
  const [readArticles, setReadArticles] = React.useState<string[]>([]);
  const { width } = useWindowDimensions();
  const [touchStartPosition, setTouchStartPosition] = useState<number | null>(
    null
  );
  const ref = React.useRef<HTMLDivElement>(null);
  const shortScreen = useMediaQuery({
    query: "(max-height: 600px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 900px)",
  });
  const isSmallMobile = useMediaQuery({
    query: "(max-width: 410px)",
  });

  const [hoveredArticleID, setHoveredArticleID] = useState<string | null>(null);
  useEffect(() => {
    document.title = "decoding the narwhal";
  }, []);
  useEffect(() => {
    if (readyToDisplayTimeline && ref.current?.clientWidth) {
      const localStorageRightPixelMargin =
        localStorage.getItem("rightPixelMargin");
      if (localStorageRightPixelMargin) {
        const updatedPixelMargin = clamp(
          MAX_RIGHT_PIXEL_MARGIN -
            (ref.current?.clientWidth ?? 0) +
            width -
            500,
          // 300 - timeline width + window width  - 500 = 776
          // window width - timeline width = 976
          MAX_RIGHT_PIXEL_MARGIN,
          Number(localStorageRightPixelMargin)
        );
        setRightPixelMargin(updatedPixelMargin);
      }
      setReadyToVisibleTimeline(true);
    }
  }, [width, ref.current?.clientWidth, readyToDisplayTimeline]);
  useEffect(() => {
    async function fetchArticles() {
      const res = await fetch(`./${process.env.PUBLIC_URL}article-index.txt`);
      const text = await res.text();
      const articleMetadata = text.split("\n");
      const newArticles: ArticleMetadata[] = [];
      for (const metadata of articleMetadata) {
        const [articleID, articleTitle, dateString, type] = metadata.split("|");
        const date = new Date(dateString);
        newArticles.push({ title: articleTitle, date, type, articleID });
      }
      newArticles.sort((a, b) => {
        return a.date.getTime() - b.date.getTime();
      });
      setArticles(newArticles);
    }
    if (!fetchedIndex) {
      fetchArticles();
      setFetchedIndex(true);
    }
  }, [articles, fetchedIndex]);

  useEffect(() => {
    const readArticles = localStorage.getItem("readArticles") || "";
    const readArticleList = readArticles.split(",");
    setReadArticles(readArticleList);
  }, []);
  if (isMobile || shortScreen) {
    return (
      <div className="h-full flex flex-col" style={{ margin: 50 }}>
        <div
          className="flex flex-col items-start"
          style={{ marginBottom: isSmallMobile ? 30 : 50 }}
        >
          <div
            className="flex flex-col"
            style={{ width: isSmallMobile ? "100%" : undefined }}
          >
            <div
              className="flex justify-between"
              style={{
                flexDirection: isSmallMobile ? "column" : "row",
                alignItems: isSmallMobile ? undefined : "flex-end",
              }}
            >
              <div style={{ marginBottom: 10 }}>
                <img src={NarwhalPng} alt="narwhal" style={{ width: 40 }} />
              </div>
              <p
                className="font-sans"
                style={{ fontSize: 12, marginTop: isSmallMobile ? 10 : 0 }}
              >
                a blog by peter lu
              </p>
            </div>
            <p
              style={{
                fontFamily: "Nothing You Could Do",
                fontSize: isSmallMobile ? 24 : 30,
              }}
            >
              decoding the narwhal
            </p>
          </div>
        </div>
        <div className="flex flex-col">
          {[...articles].reverse().map((article) => {
            return (
              <Link
                to={`/${article.articleID}`}
                key={article.articleID}
                style={{
                  opacity: readArticles.includes(article.articleID) ? 0.5 : 1,
                }}
              >
                <div
                  className="flex flex-col font-sans"
                  style={{ marginBottom: 20 }}
                >
                  <p style={{ fontSize: 16 }}>
                    {renderParagraphHtml(article.title)}
                  </p>

                  <div className="flex flex-row items-center">
                    <p style={{ fontSize: 12 }}>
                      {article.date.toLocaleDateString()}
                    </p>
                    <span
                      style={{
                        fontWeight: "bold",
                        marginLeft: 5,
                        marginRight: 5,
                      }}
                    >
                      ·
                    </span>
                    <p
                      style={{
                        fontSize: 12,
                        color:
                          article.type === "Short story" ? "#b39015" : "black",
                      }}
                    >
                      {article.type}
                    </p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
  return (
    <div
      className="h-full flex flex-col justify-center"
      style={{
        height: "100dvh",
      }}
      onWheel={(e) => {
        const deltaY = e.deltaY;
        const deltaX = e.deltaX;
        const updatedPixelMargin = clamp(
          MAX_RIGHT_PIXEL_MARGIN -
            (ref.current?.clientWidth ?? 0) +
            width -
            500,
          MAX_RIGHT_PIXEL_MARGIN,
          rightPixelMargin - deltaY + deltaX
        );
        setRightPixelMargin(updatedPixelMargin);
        localStorage.setItem("rightPixelMargin", updatedPixelMargin.toString());
      }}
      onTouchStart={(e) => {
        setTouchStartPosition(e.touches[0].clientX);
      }}
      onTouchMove={(e) => {
        // setRightPixelMargin(300);
        // localStorage.setItem("rightPixelMargin", "300");
        // return;
        // const deltaY = e.touches[0].clientY;
        if (touchStartPosition !== null) {
          const deltaX = e.touches[0].clientX - touchStartPosition;
          const updatedPixelMargin = clamp(
            MAX_RIGHT_PIXEL_MARGIN -
              (ref.current?.clientWidth ?? 0) +
              width -
              500,
            MAX_RIGHT_PIXEL_MARGIN,
            rightPixelMargin - deltaX
          );
          setRightPixelMargin(updatedPixelMargin);
          localStorage.setItem(
            "rightPixelMargin",
            updatedPixelMargin.toString()
          );
        }
        setTouchStartPosition(e.touches[0].clientX);
      }}
      onTouchEnd={(e) => {
        setTouchStartPosition(null);
      }}
    >
      <div
        className="relative"
        style={{
          overflowX: "clip",
          height: 300,
          display:
            readyToDisplayTimeline && readyToVisibleTimeline
              ? "flex"
              : "hidden",
        }}
      >
        <div
          ref={ref}
          className="absolute flex flex-row"
          style={{ right: rightPixelMargin, top: -50 }}
        >
          {articles.map((article, i) => {
            return (
              <div
                style={{
                  marginRight: 100,
                }}
                key={`article-${article.articleID}`}
                onMouseEnter={() => setHoveredArticleID(article.articleID)}
                onMouseLeave={() => setHoveredArticleID(null)}
              >
                <Link
                  to={`/${article.articleID}`}
                  style={{
                    opacity: readArticles.includes(article.articleID) ? 0.5 : 1,
                  }}
                >
                  {i % 2 === 1 && (
                    <div
                      className="flex flex-col items-center font-sans"
                      style={{
                        width: 150,
                        height: 200,
                      }}
                    >
                      <div className="flex flex-col items-end">
                        <p
                          style={{
                            fontSize: 12,
                            color:
                              article.type === "Short story"
                                ? "#b39015"
                                : "black",
                          }}
                        >
                          {article.type}
                        </p>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight:
                              hoveredArticleID === article.articleID
                                ? "bold"
                                : "normal",
                            textAlign: "right",
                          }}
                        >
                          {renderParagraphHtml(article.title)}
                        </p>
                      </div>
                      <div
                        style={{
                          height:
                            hoveredArticleID === article.articleID ? 200 : 160,
                          borderLeftWidth:
                            hoveredArticleID === article.articleID ? 2 : 1,
                          borderColor: "black",
                          opacity: readArticles.includes(article.articleID)
                            ? 0.5
                            : 1,
                        }}
                      />
                      <div style={{ marginTop: 10 }}>
                        <p>{article.date.toLocaleDateString()}</p>
                      </div>
                    </div>
                  )}
                  {i % 2 === 0 && (
                    <div
                      className="flex flex-col items-center font-sans"
                      style={{
                        width: 150,
                        height: 200,
                        marginTop: 132,
                      }}
                    >
                      <div style={{ marginBottom: 10 }}>
                        <p>{article.date.toLocaleDateString()}</p>
                      </div>
                      <div
                        style={{
                          height: 160,
                          borderLeftWidth:
                            hoveredArticleID === article.articleID ? 2 : 1,
                          borderColor: "black",
                          opacity: readArticles.includes(article.articleID)
                            ? 0.5
                            : 1,
                        }}
                      />
                      <div className="flex flex-col items-end">
                        <p
                          style={{
                            fontSize: 12,
                            color:
                              article.type === "Short story"
                                ? "#b39015"
                                : "black",
                          }}
                        >
                          {article.type}
                        </p>
                        <p
                          style={{
                            fontSize: 16,
                            textAlign: "right",
                            fontWeight:
                              hoveredArticleID === article.articleID
                                ? "bold"
                                : "normal",
                          }}
                        >
                          {renderParagraphHtml(article.title)}
                        </p>
                      </div>
                    </div>
                  )}
                </Link>
              </div>
            );
          })}
          <div style={{ width: 100 }} />
          {readyToDisplayTimeline && readyToVisibleTimeline && (
            <div
              className="absolute w-full"
              style={{
                borderBottomWidth: 1,
                borderColor: "black",
                bottom: 165,
              }}
            >
              <div
                className="absolute"
                style={{
                  right: -5,
                  top: -4.5,
                  borderTop: "5px solid transparent",
                  borderBottom: "5px solid transparent",
                  borderLeft: "10px solid black",
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
      <div
        className="absolute bottom-0 right-0 w-full flex flex-row justify-end"
        style={{ paddingRight: 50, paddingBottom: 50 }}
      >
        <div className="flex flex-col items-start">
          <div className="w-full flex flex-row items-end justify-between">
            <img src={NarwhalPng} alt="narwhal" style={{ width: 50 }} />
            <p
              className="font-sans"
              style={{
                textAlign: "right",
              }}
            >
              a blog by{" "}
              <a href="https://www.draftzero.com/writer/plu">
                <span style={{ textDecoration: "underline", color: "blue" }}>
                  peter lu
                </span>
              </a>
            </p>
          </div>
          <p
            style={{
              fontFamily: "Nothing You Could Do",
              fontSize: 40,
              textAlign: "right",
            }}
          >
            decoding the narwhal
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
